import http from "./http"
const baseUrl = 'https://pay.gxb.cn/api' 

// 已开通的企业列表
export const $apiCompanyList = params=>http({method:"get",url:'company/search',data:params})

// 主营
export const $apiMainType =()=>http( {method:"post",url:baseUrl +'/main/type'})

// 入驻企业库 钢信宝 'http://192.168.0.20/api'
export const $apiSettleInApply = params =>http({method:"post",url:baseUrl +'/settle/in/apply',data:params})
export const $apiClientApply = params =>http({method:"post",url:baseUrl +'/settle/client',data:params})


//验证是否重复提交 
export const $apiCheckBindCompany = params =>http({method:"post",url:baseUrl +'/check/bind/company',data:params})
//验证是否重复提交 
export const $apiCheckRepeat = params =>http({method:"post",url:baseUrl +'/check/company',data:params})

// 入驻审核状态
export const $apiFlairAuthState = params=>http({method:"post",url:baseUrl +'/flair/auth/state',data:params})

// 企业入住地区
export const $apiRegion = params=>http({method:"post",url:baseUrl +'/region',data:params})